/* Here the navigation's map turns into a react component */

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Collapse,
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";

export const RenderList = ({
  open,
  openSubchild,
  handleOpenSubchild,
  navigationMap,
}) => {
  return (
    <List>
      {navigationMap.map((tab) => {
        return (
          <ListItem
            key={`${tab.label}-list-item`}
            disablePadding
            sx={{ display: "block" }}
            onClick={
              tab.button
                ? (e) => tab.onclick(e)
                : () => handleOpenSubchild(tab.label)
            }
          >
            <ListItemButton
              key={`${tab.label}-list-item-button`}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={tab.click}
            >
              <ListItemIcon
                key={`${tab.label}-list-item-icon`}
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {tab.icon}
              </ListItemIcon>
              <ListItemText
                key={`${tab.label}-list-item-text`}
                primary={tab.label}
                sx={{
                  opacity: open ? 1 : 0,
                  "& span": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
              />
              {tab.children.length > 0 &&
                (openSubchild[tab.label] ? (
                  <ExpandLess
                    key={`${tab.label}-list-item-expand-less`}
                    sx={{ display: open ? "block" : "none", width: "12px" }}
                  />
                ) : (
                  <ExpandMore
                    key={`${tab.label}-list-item-expand-more`}
                    sx={{ display: open ? "block" : "none", width: "12px" }}
                  />
                ))}
            </ListItemButton>
            {tab.children.length > 0 && (
              <Collapse
                key={`${tab.label}-list-item-collapse-container`}
                in={openSubchild[tab.label]}
                unmountOnExit
              >
                <div
                  key={`${tab.label}-list-item-collapse`}
                  sx={{
                    zIndex: "10000",
                    boxShadow: "0 1px 2px 0 rgb(60 64 67 / 30%)",
                    position: "absolute",
                  }}
                >
                  <List>
                    {tab.children.map((child) => (
                      <>
                        {child.category_label ? (
                          <ListItem
                            key={`${child.label}-list-item`}
                            disablePadding
                            sx={{ display: "block" }}
                            divider
                          >
                            <ListItemText
                              key={`${child.label}-list-item-text`}
                              primary={child.label}
                              sx={{ fontWeight: 500, marginLeft: "10px" }}
                            />
                          </ListItem>
                        ) : (
                          <ListItem
                            key={`${child.label}-list-item`}
                            disablePadding
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              key={`${child.label}-list-item-button`}
                              sx={{
                                minHeight: 48,
                                justifyContent: "center",
                                px: 2.5,
                              }}
                              onClick={child.onclick}
                            >
                              <ListItemText
                                key={`${child.label}-list-item-text`}
                                primary={child.label}
                              />
                            </ListItemButton>
                          </ListItem>
                        )}

                        {child.subchildren?.map((subchild) => {
                          return (
                            <ListItem
                              key={`${subchild.label}-list-item`}
                              disablePadding
                              sx={{ display: "block" }}
                            >
                              <ListItemButton
                                key={`${subchild.label}-list-item-button`}
                                sx={{
                                  minHeight: 48,
                                  justifyContent: "center",
                                  px: 2.5,
                                }}
                                onClick={subchild.onclick}
                              >
                                <ListItemText
                                  key={`${subchild.label}-list-item-text`}
                                  primary={subchild.label}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </>
                    ))}
                  </List>
                </div>
              </Collapse>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};
