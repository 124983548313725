//architecture
import MicroFrontend from "../MicroFrontend";

export const PipefyInternalTool = {
  label: "Pipefy Internal Tool",
  path: "/pipefy-internal-tool",
  host:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001"
      : process.env.REACT_APP_PIPEFYINTERNALTOOL_HOST,

  children: [
    {
      label: "Konto erstellen",
      path: "/pipefy-internal-tool/create_company",
    },
    {
      label: "Konto löschen",
      path: "/pipefy-internal-tool/delete_company",
    },
  ],

  MFComponent: ({ history }) => {
    return (
      <MicroFrontend
        history={history}
        host={PipefyInternalTool.host}
        name="PipefyInternalTool"
      />
    );
  },
};

export const CloudTalentAPIPlayground = {
  label: "Cloud Talent API Playground",
  path: "/cloud-talent-api-playground",
  host:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3002"
      : process.env.REACT_APP_CLOUDTALENTAPIPLAYGROUND_HOST,

  children: [
    {
      label: "Search",
      category_label: false,
      path: "/cloud-talent-api-playground/search",
    },
    {
      label: "Companies",
      category_label: true,
      path: "/cloud-talent-api-playground/companies",
      subchildren: [
        {
          label: "Create Company",
          path: "/cloud-talent-api-playground/companies/create",
        },
        {
          label: "Manage Companies",
          path: "/cloud-talent-api-playground/companies/manage",
        },
      ],
    },
    {
      label: "Jobs",
      category_label: true,
      path: "/cloud-talent-api-playground/jobs",
      subchildren: [
        {
          label: "Create Job",
          path: "/cloud-talent-api-playground/jobs/create",
        },
        {
          label: "Manage Jobs",
          path: "/cloud-talent-api-playground/jobs/manage",
        },
      ],
    },
    {
      label: "Tenants",
      category_label: true,
      path: "/cloud-talent-api-playground/tenants",
      subchildren: [
        {
          label: "Create Tenant",
          path: "/cloud-talent-api-playground/tenants/create",
        },
        {
          label: "Manage Tenants",
          path: "/cloud-talent-api-playground/tenants/manage",
        },
      ],
    },
  ],

  MFComponent: ({ history }) => {
    return (
      <MicroFrontend
        history={history}
        host={CloudTalentAPIPlayground.host}
        name="CloudTalentAPIPlayground"
      />
    );
  },
};

export const Home = {
  label: "Home",
  path: "/",
  host:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : process.env.REACT_APP_HOME_HOST,
  children: [],
};

export const User = {
  label: "User",
  path: "/user/info",
  host:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : process.env.REACT_APP_HOME_HOST,
  children: [
    {
      label: "Info",
      path: "/user/info",
    },
    {
      label: "Manage",
      path: "/user/manage",
    },
  ],
};
