//auth
import { auth } from "./config";
import { signOut, signInWithEmailAndPassword } from "firebase/auth";

export const handleSignOut = (e) => {
  e.preventDefault();

  signOut(auth)
    .then(() => {
      console.log("fired");
      sessionStorage.removeItem("accessToken");
      window.location.reload();
    })
    .catch((err) => alert(err));
};

export const handleSignIn = (e, email, password) => {
  e.preventDefault();

  signInWithEmailAndPassword(auth, email, password)
    .then((data) => {
      sessionStorage.setItem("accessToken", data.user.accessToken);
    })
    .catch((error) => {
      alert(error.message);
    });
};
