import { useState } from "react";

//components
import { Button, Container, Typography } from "@mui/material";
import { TextField } from "@mui/material";

//auth
import { handleSignIn } from "../auth/utils";

const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <Container
        maxWidth="sm"
        component={"form"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          "& *": {
            marginBottom: "10px",
            width: "100%",
          },
        }}
      >
        <Typography
          variant="h1"
          fontFamily={""}
          fontSize={34}
          gutterBottom
          width={"fit-content"}
        >
          Anmelden
        </Typography>
        <TextField
          label="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          label="Passwort"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button onClick={(e) => handleSignIn(e, email, password)}>
          submit
        </Button>
      </Container>
      <Container
        maxWidth="sm"
        component={"form"}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "auto",
          position: "absolute",
          bottom: "2%",
          left: "50%",
          transform: "translate(-50%, -2%)",
          "& *": {
            marginBottom: "5px",
            width: "100%",
          },
        }}
      >
        Made with ❤️ by GC
      </Container>
    </>
  );
};

export default Auth;
