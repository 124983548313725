import { getAuth } from "firebase/auth";

import firebase from "firebase/compat/app";

const firebaseConfig = {
  apiKey: "AIzaSyCU0zEjTzy4qeSsMlPsRe24ubj0pgBFXIg",
  authDomain: "p-internal-dashboard.firebaseapp.com",
  projectId: "p-internal-dashboard",
  storageBucket: "p-internal-dashboard.appspot.com",
  messagingSenderId: "931149064191",
  appId: "1:931149064191:web:3712fe1e535f1a0bb7e9ca",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
