import * as React from "react";

//material components
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  Avatar,
} from "@mui/material";

//components
import { RenderList } from "./NavbarLists";

//material styles
import { Drawer, DrawerHeader, AppBar } from "./NavbarStyles";

//assets
import logo_icon from "../../assets/img/logo_icon.png";

//navigation map
import * as NavigationMap from "./NavigationMap";

export const Navbar = ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const [openSubchild, setOpenSubchild] = React.useState({});

  const handleOpenSubchild = (id) => {
    setOpenSubchild((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            display="flex"
            alignItems="center"
            fontWeight="500"
          >
            <img
              src={logo_icon}
              width={120}
              style={{ marginRight: "8px" }}
              alt="professional.ch Logo"
            />
            Internal Platform
          </Typography>

          <Box
            display="flex"
            p={2}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography mr={2}>{user?.email}</Typography>
              <Avatar
                alt="profile image"
                src={user?.photoURL}
                sx={{ width: 30, height: 30 }}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => {
          setOpen(false);
          setOpenSubchild({});
        }}
      >
        <DrawerHeader></DrawerHeader>
        <Divider />

        <List>
          <RenderList
            open={open}
            openSubchild={openSubchild}
            handleOpenSubchild={handleOpenSubchild}
            navigationMap={NavigationMap.pages}
          />
        </List>
        <Divider />
        {user && (
          <List>
            <RenderList
              open={open}
              openSubchild={openSubchild}
              handleOpenSubchild={handleOpenSubchild}
              navigationMap={NavigationMap.user}
            />
          </List>
        )}
      </Drawer>
    </Box>
  );
};
