/* Here the navigation is mapped in a Object-like format, to provide it to other components  */

//icons
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

//utils
import { handleSignOut } from "../../auth/utils";

//pages
import * as PagesMap from "../../pages/pageMask";

//assets
import pipefy_logo from "../../assets/img/pipefy_logo.svg";
import google_cloud_logo from "../../assets/img/google_cloud_logo.png";

export const pages = [
  {
    label: PagesMap.Home.label,
    path: PagesMap.Home.path,
    icon: <HomeIcon />,
    button: true,
    onclick: () => {
      window.location.href = PagesMap.Home.path;
    },
    children: PagesMap.PipefyInternalTool.children?.map((child) => {
      return {
        label: child?.label,
        path: child?.path,
        onclick: () => {
          window.location.href = child?.path;
        },
        category_label: child?.category_label,
      };
    }),
  },
  {
    label: "Pipefy",
    path: PagesMap.PipefyInternalTool.path,
    icon: (
      <div
        style={{
          width: "24px",
          height: "24px",
          backgroundImage: `url(${pipefy_logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    ),
    onclick: () => {
      window.location.href = PagesMap.PipefyInternalTool.path;
    },
    children: PagesMap.PipefyInternalTool.children?.map((child) => {
      return {
        label: child?.label,
        path: child?.path,
        onclick: () => {
          window.location.href = child?.path;
        },
        category_label: child?.category_label,
      };
    }),
  },
  {
    label: "Cloud Talent API Playground",
    path: PagesMap.CloudTalentAPIPlayground.path,
    button: false,
    icon: (
      <div
        style={{
          width: "24px",
          height: "24px",
          backgroundImage: `url(${google_cloud_logo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    ),
    onclick: () => {
      window.location.href = PagesMap.CloudTalentAPIPlayground.path;
    },
    children: PagesMap.CloudTalentAPIPlayground.children?.map((child) => {
      return {
        label: child?.label,
        path: child?.path,
        onclick: () => {
          window.location.href = child?.path;
        },
        category_label: child?.category_label,
        subchildren: child?.subchildren?.map((subchild) => {
          return {
            label: subchild?.label,
            path: subchild?.path,
            onclick: () => {
              window.location.href = subchild?.path;
            },
          };
        }),
      };
    }),
  },
];

export const user = [
  {
    label: PagesMap.User.label,
    path: PagesMap.User.path,
    icon: <PersonIcon />,
    button: false,
    onclick: () => {
      window.location.href = PagesMap.User.path;
    },
    children: PagesMap.User.children?.map((child) => {
      return {
        label: child?.label,
        path: child?.path,
        onclick: () => {
          window.location.href = child?.path;
        },
        category_label: child?.category_label,
      };
    }),
  },
  {
    label: "Abmelden",
    path: null,
    icon: <LogoutIcon />,
    onclick: (e) => handleSignOut(e),
    button: true,
    children: [],
  },
];
