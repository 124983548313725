import React from "react";

//router
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

//auth
import { AuthContext } from "./auth/Context";
import { useContext } from "react";

//styles
import "./App.css";

//pages
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import ManageUsers from "./pages/ManageUsers";
import InfoUser from "./pages/InfoUser";

//components
import { Navbar } from "./components/Navbar/Navbar";

//MFComponents
import { PipefyInternalTool, CloudTalentAPIPlayground } from "./pages/pageMask";

import { createBrowserHistory } from "history";

const defaultHistory = createBrowserHistory();

const App = ({ history = defaultHistory }) => {
  const { user, loading, error, isTokenExpired } = useContext(AuthContext);

  if (loading) {
    //waiting for a loader to be implemented
    return <div>Loading...</div>;
  }

  if (error) {
    alert(error);
  }

  if (!user) {
    return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/auth" exact element={<Auth />} />
          <Route path="*" element={<Navigate to="/auth" />} />
        </Routes>
      </Router>
    );
  }

  if (isTokenExpired) {
    //refresh the token if the user is loggedIn and but the token is expired
    user.getIdToken(true).then((idToken) => {
      sessionStorage.setItem("accessToken", idToken);
    });
  }

  //Nested routes should be declared at the top level as well !!!
  return (
    <Router>
      <Navbar user={user} />
      <Routes>
        <Route path="/" element={<Home />} />

        {/*  ------------------- USER MANAGEMENT ------------------- */}
        <Route path="/user/info" element={<InfoUser />} />
        <Route path="/user/manage" element={<ManageUsers />} />

        {/*  ------------------- PIPEFY INTERNAL TOOL ------------------- */}
        <Route
          path="/pipefy-internal-tool"
          element={<Navigate to="/pipefy-internal-tool/create_company" />}
        />

        <Route
          path="/pipefy-internal-tool/create_company"
          element={<PipefyInternalTool.MFComponent history={history} />}
        />
        <Route
          path="/pipefy-internal-tool/delete_company"
          element={<PipefyInternalTool.MFComponent history={history} />}
        />

        {/*  ------------------- CLOUD TALENT SOLUTION API PLAYGROUND ------------------- */}

        <Route
          path="/cloud-talent-api-playground"
          element={<Navigate to="/cloud-talent-api-playground/search" />}
        />
        <Route
          path="/cloud-talent-api-playground/search"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />
        <Route
          path="/cloud-talent-api-playground/companies/create"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />
        <Route
          path="/cloud-talent-api-playground/companies/manage"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />
        <Route
          path="/cloud-talent-api-playground/jobs/create"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />
        <Route
          path="/cloud-talent-api-playground/jobs/manage"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />
        <Route
          path="/cloud-talent-api-playground/tenants/create"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />
        <Route
          path="/cloud-talent-api-playground/tenants/manage"
          element={<CloudTalentAPIPlayground.MFComponent history={history} />}
        />

        {/*  ------------------- CLOUD TALENT SOLUTION API PLAYGROUND ------------------- */}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
