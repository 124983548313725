import React from "react";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: " 50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      Dashboard coming soon..
    </div>
  );
};

export default Home;
