import { createContext } from "react";

//auth
import { auth } from "./config";
import { useAuthState } from "react-firebase-hooks/auth";

export const AuthContext = createContext({
  user: null,
  loading: false,
  error: null,
});

export const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);

  //Whenever the token expires, the children apps will remove it from the sessionStorage
  const isTokenExpired = !sessionStorage.getItem("accessToken");

  return (
    <AuthContext.Provider value={{ user, loading, error, isTokenExpired }}>
      {children}
    </AuthContext.Provider>
  );
};
